import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTable, DataTableFilterMeta } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useCreateAttitudeSet, useGetAttitudeSet, useGetAttitudeSets, useUpdateAttitudeSet } from '../api/attitude-set-API'
import { CopyIcon, DeleteIcon, EditIcon, PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, AttitudeSet, SelectOption } from '../types/types'
import { queryString2JsonString } from '../types/util'
import { DefaultPageSize } from '../utils/constants'
import { attitudeSetListState, currentTeamState } from '../utils/state-atoms'
import SaveButton from '../components/buttons/SaveButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { DropdownStyle, TabMenuStyle } from '../utils/styling-constants'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Column, ColumnBodyOptions } from 'primereact/column'
import { AttitudeState, Trajectory } from '@aero-platform/shared'
import IconButton from '../components/buttons/IconButton'
import AttitudeSetStateEditor from '../components/AttitudeSetStateEditor'
import AttitudeSetTrajectoryEditor from '../components/AttitudeSetTrajectoryEditor'
import { confirmDialog } from 'primereact/confirmdialog'
import { TabMenu } from 'primereact/tabmenu'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  methodId: { value: null, matchMode: FilterMatchMode.EQUALS },
  status: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const tabItems = [
  { label: 'Config' },
  { label: 'Trajectories' },
  { label: 'Logs' }
];

const stateColumns = [
  {
    header: 'Name',
    field: 'name',
    filter: true,
    headerStyle: {},
    bodyStyle: {},
    bodyClassName: 'id-cell max-w-[200px] w-[200px]',
    headerClassName: 'max-w-[200px]',
    sortable: true
  },
  {
    header: 'FRH',
    field: 'frh',
    headerClassName: 'max-w-[150px]',
    bodyClassName: 'max-w-[150px] w-[80px]',
  },
  {
    header: 'RRH',
    field: 'rrh',
    headerClassName: 'max-w-[150px]',
    bodyClassName: 'max-w-[150px] w-[80px]',
  },
  {
    header: 'Steer',
    field: 'steer',
    headerClassName: 'max-w-[150px]',
    bodyClassName: 'max-w-[150px] w-[80px]',
  },
  {
    header: 'Roll',
    field: 'roll',
    headerClassName: 'max-w-[150px]',
    bodyClassName: 'max-w-[150px] w-[80px]',
    body: (rowData: AttitudeState) => {
      const num = rowData.roll
      return <span>{num && num.toFixed(1)}</span>
    }
  },
  {
    header: 'Yaw',
    field: 'yaw',
    headerClassName: 'max-w-[150px]',
    bodyClassName: 'max-w-[150px] w-[80px]',
  },
  {
    header: 'Rad',
    field: 'radius',
    headerClassName: 'max-w-[150px]',
    bodyClassName: 'max-w-[150px] w-[80px]',
  },
  {
    header: 'Spd',
    field: 'speed',
    headerClassName: 'max-w-[150px]',
    bodyClassName: 'max-w-[150px] w-[80px]',
  },
  {
    header: 'Exh',
    field: 'exhaust',
    headerClassName: 'max-w-[150px]',
    bodyClassName: 'max-w-[150px] w-[80px]',
  }
]

const trajectoryColumns = [
  {
    header: 'Name',
    field: 'name',
    headerStyle: {},
    headerClassName: 'w-[200px]',
    bodyStyle: {},
    bodyClassName: 'max-w-[200px] id-cell',
    sortable: true
  },
  {
    header: 'States',
    field: 'states',
    body: (rowData: Trajectory) => {
      return (
        <div className="flex items-center space-x-1">
          {rowData.attitudeStates && rowData.attitudeStates.join(', ')}
        </div>
      )
    }
  }
]


const AttitudeSets: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [mode, setMode] = useState('')
  const [editorMode, setEditorMode] = useState('')
  const [stateIndex, setStateIndex] = useState(0)
  const [trajIndex, setTrajIndex] = useState(0)
  const [showStateEditor, setShowStateEditor] = useState(false)
  const [showTrajectoryEditor, setShowTrajectoryEditor] = useState(false)
  const [{ loading: loadingAttitudes, data: attitudeSetsData }, fetchAttitudeSets] = useGetAttitudeSets()
  const [{ loading: loadingAttitude, data: oneAttitudeSetData }, fetchOneAttitudeSet] = useGetAttitudeSet()
  const [{ loading: creating }, createAttitudeSet] = useCreateAttitudeSet()
  const [{ loading: updating }, updateAttitudeSet] = useUpdateAttitudeSet()

  const [globalAttitudeSet, setGlobalAttitudeSet] = useRecoilState(attitudeSetListState)
  const [attitudeSets, setAttitudeSets] = useState<AttitudeSet[]>([])
  const [currentAttitudeSet, setCurrentAttitudeSet] = useState({} as AttitudeSet)
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [attitudeStates, setAttitudeStates] = useState<AttitudeState[]>([] as AttitudeState[])
  const [trajectories, setTrajectories] = useState<Trajectory[]>([] as Trajectory[])
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,attitudeSetList:0,cfdSequenceList:0,method:0'
  })

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<AttitudeSet>()

  useEffect(() => {
    if (attitudeSetsData) {
      const tmpList = attitudeSetsData.protoBytesList.map((item: string) => {
        return protoService.deSerialize(item, 'attitudeset')
      })
      tmpList && setAttitudeSets(tmpList)
    } else {
      setAttitudeSets([])
    }
  }, [attitudeSetsData])

  useEffect(() => {
    if (oneAttitudeSetData) {
      if (oneAttitudeSetData.protoBytesList && oneAttitudeSetData.protoBytesList.length) {
        const attitudeSet = protoService.deSerialize(oneAttitudeSetData.protoBytesList[0], 'attitudeset')
        setCurrentAttitudeSet(attitudeSet)
        setAttitudeStates(attitudeSet.attitudeStates.map((item) => {
          return { ...item } as AttitudeState
        }))
        setTrajectories(attitudeSet.trajectories.map((item) => {
          return { ...item } as Trajectory
        }))
      } else {
        setAttitudeSets([])
        setTrajectories([])
        toast.error('Error loading attitude set.')
      }
    }
  }, [oneAttitudeSetData])

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.methodId['value']) {
      const q = 'methodId=' + state.filters.methodId['value']
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.status['value']) {
      const q = 'status=' + state.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchAttitudeSets(query).catch((err) => {
      console.error(err)
      toast.error('Error loading attitudeSets.')
    })
  }, [lazyState])

  const handleCreateNewAttitudeSet = () => {
    if (mode === 'new') {
      return
    }
    reset()
    setMode('new')
    setValue('name', "")
    setValue('description', "")
    setCurrentAttitudeSet({} as AttitudeSet)
    setAttitudeStates([])
    setTrajectories([])
  }

  const handleCreateNewState = () => {
    setEditorMode('new')
    setShowStateEditor(true)
  }

  const handleCreateNewTrajectory = () => {
    setEditorMode('new')
    setShowTrajectoryEditor(true)
  }

  const handleCopyAttitudeSet = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new attitude set.')
      return
    }
    if (!currentAttitudeSet._id) {
      toast.error('Please select a attitude set to duplicate.')
      return
    }

    setMode('new')
    setValue('name', currentAttitudeSet.name + ' (Copy)')
    setValue('description', currentAttitudeSet.description)
    setAttitudeStates(attitudeStates.map((item) => {
      return { ...item } as AttitudeState
    }))
    setTrajectories(trajectories.map((item) => {
      return { ...item } as Trajectory
    }))
  }

  const handleItemSelected = (attitudeSet) => {
    if (!attitudeSet) {
      return
    }
    fetchOneAttitudeSet(attitudeSet._id).catch((err) => {
      toast.error('Error loading attitude set.')
    })

    setCurrentAttitudeSet(attitudeSet)
    setMode('edit')
    reset()
    setValue('name', attitudeSet.name)
    setValue('description', attitudeSet.description)
    if (attitudeSet.attitudeStates) {
      setAttitudeStates(attitudeSet.attitudeStates.map((item) => {
        return { ...item } as AttitudeState
      }))
    } else {
      setAttitudeStates([])
    }
    if (attitudeSet.trajectories) {
      setTrajectories(attitudeSet.trajectories.map((item) => {
        return { ...item } as Trajectory
      }))
    } else {
      setTrajectories([])
    }
  }

  const onSubmit: SubmitHandler<AttitudeSet> = async (formData: AttitudeSet) => {
    if (mode === 'new') {  // create new attitude set
      const newAttitudeSet = new AttitudeSet(formData)
      newAttitudeSet.raceTeamId = currentTeam
      newAttitudeSet.attitudeStates = attitudeStates.map((item) => {
        return new AttitudeState(item)
      })
      newAttitudeSet.trajectories = trajectories.map((item) => {
        return new Trajectory(item)
      })
      const dataToPost = protoService.encodePayload(newAttitudeSet, 'attitudeset')
      try {
        const res = await createAttitudeSet({ data: dataToPost })
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const savedAttitudeSet: AttitudeSet = protoService.deSerialize(res.data.protoBytesList[0], 'attitudeset')
          setAttitudeSets([...attitudeSets, savedAttitudeSet])
          setCurrentAttitudeSet(savedAttitudeSet)
          setGlobalAttitudeSet([...globalAttitudeSet, savedAttitudeSet])
          toast.success('Attitude set created.')
          setMode('edit')
        }
      } catch (err) {
        console.error(err)
        toast.error('Error creating attitude set.')
      }
    } else { // update existing attitude set
      if (!currentAttitudeSet._id) {
        return
      }
      const newAttitudeSet = new AttitudeSet(formData)
      newAttitudeSet._id = currentAttitudeSet._id
      newAttitudeSet.raceTeamId = currentTeam
      newAttitudeSet.attitudeStates = attitudeStates.map((item) => {
        return new AttitudeState(item)
      })
      newAttitudeSet.trajectories = trajectories.map((item) => {
        return new Trajectory(item)
      })
      const dataToPost = protoService.encodePayload(newAttitudeSet, 'attitudeset')
      try {
        await updateAttitudeSet({
          id: currentAttitudeSet._id,
          data: dataToPost
        } as any)
        setAttitudeSets(
          attitudeSets.map((item) => {
            if (item._id === currentAttitudeSet._id) {
              return newAttitudeSet
            } else {
              return item
            }
          })
        )
        setCurrentAttitudeSet(newAttitudeSet)
        toast.success('Attitude set updated.')
      } catch (err) {
        console.error(err)
        toast.error('Error updating attitude set.')
      }
    }

  }

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Attitude Sets</h1>
        <Button
          type="button"
          onClick={handleCreateNewAttitudeSet}
          severity="secondary"
          className="icon-button"
          disabled={mode === 'new'}
          tooltip='Create New Attitude Set'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+45 bottom'
        >
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full grid grid-cols-2 gap-x-2 gap-y-4 mt-8">
        <div className="flex items-center col-span-2 space-x-2">
          <Dropdown
            filter
            loading={loadingAttitudes}
            filterBy="name"
            filterMatchMode="contains"
            showFilterClear
            onFilter={(e) => {
              setlazyState({
                ...lazyState,
                filters: {
                  ...lazyState.filters,
                  name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
                }
              })
            }}
            value={currentAttitudeSet}
            emptyMessage="No AttitudeSets Found"
            pt={{
              ...DropdownStyle,
              root: {
                ...DropdownStyle.root,
                className: 'min-w-[30%] max-w-[50%]'
              },
            }}
            optionValue="_id"
            optionLabel="name"
            useOptionAsValue
            options={attitudeSets}
            valueTemplate={(option) => {
              let value
              if (mode === 'new') {
                value = 'New AttitudeSet'
              } else if (option) {
                value = option.name
              } else {
                value = (currentAttitudeSet && currentAttitudeSet.name) || 'Select Attitude Set'
              }
              return <div>{value}</div>
            }}
            itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
            onChange={(e) => handleItemSelected(e.value)}
            placeholder="Select Attitude Set"
          />
          <Button
            type="button"
            onClick={handleCopyAttitudeSet}
            className="icon-button"
            tooltip='Duplicate Attitude Set'
            tooltipOptions={{ position: "bottom" }}
            data-pr-at='center+5 bottom'>
            <CopyIcon className="fill-white w-4 h-4" />
          </Button>
          <SaveButton loading={creating || updating} title="Save Attitude Set" />
        </div>
        <div className="col-span-2">
          <TabMenu model={tabItems}
            onChange={(e) => console.log(e)}
            activeIndex={currentTabIndex}
            pt={TabMenuStyle}
            onTabChange={(e) => setCurrentTabIndex(e.index)} />
          <div className='min-h-4'> </div>
          {currentAttitudeSet && currentTabIndex === 0 && (
            <>
              <section className="grid grid-cols-2 space-x-2">
                <div className="field-box">
                  <Controller
                    name="name"
                    control={control}
                    disabled={creating || updating}
                    rules={{ required: 'Name is required.' }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="mb-2">
                          Name
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({ error: fieldState.error })}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>

                <div className="field-box">
                  <Controller
                    name="description"
                    control={control}
                    disabled={creating || updating}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="mb-2">
                          Description
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({ error: fieldState.error })}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </section>
              <section className="col-span-2 flex flex-col items-stretch">
                <div className="font-semibold" >States:</div>
                <DataTable
                  value={attitudeStates}
                  loading={loadingAttitude}
                  dataKey="name"
                  filterDisplay="row"
                  stripedRows
                  sortField='vehicleId'
                  pt={{
                    bodyRow: {
                      className: 'table-row'
                    },
                  }}>
                  {stateColumns.map((item) => {
                    return (
                      <Column
                        key={item.field}
                        field={item.field}
                        header={item.header}
                        showFilterMenu={false}
                        sortable={item.sortable}
                        headerStyle={item.headerStyle}
                        headerClassName={item.headerClassName}
                        bodyStyle={item.bodyStyle}
                        body={item.body}
                        bodyClassName={item.bodyClassName}></Column>
                    )
                  })}
                  <Column
                    header={() => {
                      return (
                        <div className="flex min-w-full justify-end">
                          <Button
                            type="button"
                            onClick={handleCreateNewState}
                            className="icon-button"
                            tooltip='Create New State'
                            tooltipOptions={{ position: "bottom" }}
                            data-pr-at='center+5 bottom'>
                            <PlusIcon className="fill-white w-4 h-4" />
                          </Button></div>
                      )
                    }}
                    body={(rowData: AttitudeState, options: ColumnBodyOptions) => {
                      return (
                        <div className="flex items-center space-x-1">
                          <IconButton
                            clear
                            icon={<EditIcon className="h-4 w-4 fill-gm-blue stroke-transparent" />}
                            onClick={() => {
                              setStateIndex(options.rowIndex)
                              setEditorMode('edit')
                              setShowStateEditor(true)
                            }}
                          >
                          </IconButton>
                          <IconButton
                            clear
                            icon={<DeleteIcon className="h-4 w-4 fill-error stroke-transparent" />}
                            onClick={() => {
                              const rowName = rowData.name
                              confirmDialog({
                                message: `Are you sure you want to delete the state: '${rowName}'?`,
                                header: 'Confirmation',
                                icon: 'pi pi-exclamation-triangle',
                                defaultFocus: 'reject',
                                rejectClassName: 'bg-gray-500 text-white',
                                accept: () => {
                                  const newStates = attitudeStates.filter((item) => {
                                    return item.name !== rowName
                                  })
                                  setAttitudeStates(newStates)
                                }
                              })
                            }}
                          >
                          </IconButton>
                        </div>
                      )
                    }}
                    headerClassName="one-col-editor-header editor-header"
                    pt={{
                      headerContent: {
                        className: 'pr-0 min-w-full'
                      },
                      headerTitle: {
                        className: 'pr-0 w-full flex items-center'
                      },
                    }}
                    bodyStyle={{ textAlign: 'center', padding: '12px 8px' }}></Column>
                </DataTable>
              </section>
            </>)
          }

          {currentAttitudeSet && currentTabIndex === 1 &&
            <section className="col-span-2">
              <DataTable
                value={trajectories}
                loading={loadingAttitude}
                dataKey="name"
                filterDisplay="row"
                stripedRows
                sortField='vehicleId'
                pt={{
                  bodyRow: {
                    className: 'table-row'
                  },
                }}>
                {trajectoryColumns.map((item) => {
                  return (
                    <Column
                      key={item.field}
                      field={item.field}
                      header={item.header}
                      showFilterMenu={false}
                      sortable={item.sortable}
                      headerStyle={item.headerStyle}
                      headerClassName={item.headerClassName}
                      bodyStyle={item.bodyStyle}
                      body={item.body}
                      bodyClassName={item.bodyClassName}></Column>
                  )
                })}
                <Column
                  header={() => {
                    return (
                      <div className="flex min-w-full justify-end">
                        <Button
                          type="button"
                          onClick={handleCreateNewTrajectory}
                          className="icon-button"
                          tooltip='Create New Trajectory'
                          tooltipOptions={{ position: "bottom" }}
                          data-pr-at='center+5 bottom'>
                          <PlusIcon className="fill-white w-4 h-4" />
                        </Button></div>
                    )
                  }}
                  body={(rowData: AttitudeState, options: ColumnBodyOptions) => {
                    return (
                      <div className="flex items-center space-x-1">
                        <IconButton
                          clear
                          icon={<EditIcon className="h-4 w-4 fill-gm-blue stroke-transparent" />}
                          onClick={() => {
                            if (showTrajectoryEditor) {
                              return
                            }
                            setTrajIndex(options.rowIndex)
                            setEditorMode('edit')
                            setShowTrajectoryEditor(true)
                          }}
                        >
                        </IconButton>
                        <IconButton
                          clear
                          icon={<DeleteIcon className="h-4 w-4 fill-error stroke-transparent" />}
                          onClick={() => {
                            const rowName = rowData.name
                            confirmDialog({
                              message: `Are you sure you want to delete the trajectory: '${rowName}'?`,
                              header: 'Confirmation',
                              icon: 'pi pi-exclamation-triangle',
                              defaultFocus: 'reject',
                              rejectClassName: 'bg-gray-500 text-white',
                              accept: () => {
                                const newTrajs = trajectories.filter((item) => {
                                  return item.name !== rowName
                                })
                                setTrajectories(newTrajs)
                              }
                            })
                          }}
                        >
                        </IconButton>
                      </div>
                    )
                  }}
                  headerClassName="one-col-editor-header editor-header"
                  pt={{
                    headerContent: {
                      className: 'pr-0 min-w-full'
                    },
                    headerTitle: {
                      className: 'pr-0 w-full flex items-center'
                    },
                  }}
                  bodyStyle={{ textAlign: 'center', padding: '12px 8px' }}></Column>
              </DataTable>
            </section>
          }
        </div>
      </form>

      {showStateEditor &&
        <AttitudeSetStateEditor
          mode={editorMode}
          stateIndex={stateIndex}
          states={attitudeStates}
          onConfirm={(newState, stateMode) => {
            let newStates
            if (stateMode === 'new') {
              newStates = attitudeStates.map((item) => {
                return { ...item }
              })
              newStates.push(newState)
            } else if (stateMode === 'edit') {
              newStates = attitudeStates.map((item, index) => {
                if (index === stateIndex) {
                  return newState
                } else {
                  return { ...item }
                }
              })
            }
            setAttitudeStates(newStates)
            setShowStateEditor(false)
          }}
          onCancel={() => {
            setShowStateEditor(false)
          }}
        />}

      {showTrajectoryEditor && <AttitudeSetTrajectoryEditor
        mode={editorMode}
        allStates={attitudeStates.map((item) => {
          return { label: item.name, value: item.name } as SelectOption
        })}
        trajectories={currentAttitudeSet.trajectories || []}
        trajIndex={trajIndex}
        onConfirm={(newTrajectory, trajectoryMode) => {
          let newTrajectories
          if (trajectoryMode === 'new') {
            newTrajectories = trajectories.map((item) => {
              return new Trajectory(item)
            })
            newTrajectories.push(newTrajectory)
          } else if (trajectoryMode === 'edit') {
            newTrajectories = trajectories.map((item, index) => {
              if (index === trajIndex) {
                return newTrajectory
              } else {
                return item
              }
            })
          }
          setTrajectories(newTrajectories)
          setShowTrajectoryEditor(false)
        }}
        onCancel={() => {
          setShowTrajectoryEditor(false)
        }}
      />
      }
    </div>
  )
}

export default AttitudeSets
