import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { FC, useState } from 'react'
import Modal from '../Modal'
import { useGetSubmission } from '../../api/submission-API'
import { LoadingIcon } from '../Icons'
import toast from 'react-hot-toast'
import { protoService } from '../../proto/ProtoService'
import { WorkflowSubmission } from '../../types/types'

type PeriviewModalProps = {
  caseName: string
  caseId: string
  initReference: string
  onConfirm: () => void
  onCancel: () => void
}

const PeriviewModal: FC<PeriviewModalProps> = ({ caseName, caseId, initReference, onConfirm, onCancel }) => {
  const [{ loading: loadingRun }, loadRun] = useGetSubmission()
  const [refRunId, setRefRunId] = useState<string>(initReference)

  const handlePeriview = async () => {
    if (loadingRun) {
      return
    }
    if (!refRunId) {
      toast.error('Please enter a reference run ID')
      return
    }
    loadRun(refRunId).then((res) => {
      const submissionData = res.data
      const submissions = protoService.decodeProtoResponse(
        submissionData,
        'workflowsubmission'
      ) as WorkflowSubmission[]

      if (submissions.length) {
        const run = submissions[0]
        if (run.submissionRunGroups) {
          let refCase
          run.submissionRunGroups.forEach((group) => {
            if (refCase) {
              return
            }
            if (group.workflowRunGroups) {
              refCase = group.workflowRunGroups.find((workflowGroup) => {
                return workflowGroup.name === caseName
              })
            }
          })

          if (refCase) {
            const refCaseId = refCase.systemRunId.split('-')[1]
            if (refCaseId) {
              try {
                window.open(`periview://${caseId} ${refCaseId}`, '_blank')
              } catch (e) {
                console.error(e)
              }
              onConfirm && onConfirm()
            } else {
              toast.error('Reference run does not have a case')
            }
          } else {
            toast.error('Reference run does not have the same case: ' + caseName)
          }
        } else {
          toast.error('Reference run does not have any groups.')
        }
      } else {
        toast.error('Reference run not found.')
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading reference run.')
    })
  }


  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title="Open Periview"
      showCancelButton={true}
      zIndex={30}
      size="md"
      onConfirm={() => {
        onConfirm && onConfirm()
      }}>
      <div className="relative w-full grid grid-cols-1 gap-y-4 mt-4">
        <div className="flex flex-col items-stretch">
          <label htmlFor="caseName" className="mb-2">
            Case
          </label>
          <InputText
            id={caseName}
            readOnly
            value={caseName}
            className='border-none'
          />
        </div>
        <div className="flex flex-col items-stretch">
          <label htmlFor="refRun" className="mb-2">
            Reference Run
          </label>
          <div className="flex items-center relative">
            <InputText
              id="refRun"
              className="w-full"
              defaultValue={refRunId}
              value={refRunId}
              onChange={(e) => {
                setRefRunId(e.target.value)
              }}
            />
            <div className="absolute right-2 top-[50%] translate-y-[-50%] z-10">
              {loadingRun && <LoadingIcon className="fill-gray-400 w-4 h-4 animate-spin" />}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-4">
          <Button
            type="submit"
            disabled={loadingRun}
            label="Open Periview"
            onClick={handlePeriview}
          />
        </div>
      </div>
    </Modal >
  )
}

export default PeriviewModal
