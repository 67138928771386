import { useMsal } from '@azure/msal-react'
import { Dropdown } from 'primereact/dropdown'
import { Sidebar } from 'primereact/sidebar'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ReactComponent as LogoIcon } from '../assets/svg/gm-logo.svg'
import { ReactComponent as HamburgerIcon } from '../assets/svg/hamburger.svg'
import { SelectOption } from '../types/types'
import { GMMSRaceTeamKey } from '../utils/constants'
import { appEventInstance } from '../utils/event'
import { currentUserState, teamListState, themeState } from '../utils/state-atoms'
import { InputSwitch } from 'primereact/inputswitch'
import { DropdownStyle } from '../utils/styling-constants'

const savedTeam = localStorage.getItem(GMMSRaceTeamKey)

export default function Example() {
  const navigate = useNavigate()
  const [theme, setTheme] = useRecoilState(themeState)
  const [showSidebar, setShowSidebar] = useState(false)
  const { instance: msalInstance } = useMsal()
  const currentUser = useRecoilValue(currentUserState)
  const [selectedTeam, setSelectedTeam] = useState(savedTeam || '')
  const [teamList, setTeamList] = useState<SelectOption[]>([])
  const teams = useRecoilValue(teamListState)

  useEffect(() => {
    setTeamList(
      teams.map((item) => {
        return {
          label: item,
          value: item
        }
      })
    )
  }, [teams])

  const handleSignOut = () => {
    msalInstance.logoutRedirect().catch((error) => console.error(error))
  }

  return (
    <div className="flex flex-col items-stretch w-full !pt-0 px-0">
      <div className="flex items-center h-16 mx-6">
        <div className="flex items-center !w-auto space-x-6">
          <label tabIndex={0} className="outline-none">
            <HamburgerIcon
              className="w-[30px] h-[25px] fill-gm-blue dark:fill-light-blue"
              role="button"
              onClick={() => setShowSidebar(true)}
            />
          </label>
          <label tabIndex={0} className="outline-none">
            <LogoIcon
              className="w-8 h-8 fill-gm-blue dark:fill-light-blue"
              role="button"
              onClick={() => navigate('/')}
            />
          </label>
          <div className="text-[1.2em] text-gm-blue dark:text-light-blue uppercase">Aero Design</div>
        </div>
        {currentUser && (
          <div className="items-center ml-2 flex grow justify-end">
            <div className="pr-2 dark:text-neutral">Team: </div>
            <Dropdown
              value={selectedTeam}
              pt={{
                ...DropdownStyle,
                root: {
                  ...DropdownStyle.root,
                  className: 'w-48 !bg-transparent text-white dark:border-neutral-10 dark:text-neutral-10'
                },
              }}
              placeholder="Select a team"
              onChange={(e) => {
                localStorage.setItem(GMMSRaceTeamKey, e.value)
                appEventInstance.teamChanged()
                setSelectedTeam(e.value)
              }}
              options={teamList}
            />
          </div>
        )}

      </div>
      <Sidebar
        visible={showSidebar}
        onHide={() => setShowSidebar(false)}
        pt={{
          root: {
            className: 'min-w-fit !border-0'
          },
          closeButtonIcon: {
            className: 'border-0 hover:border-0'
          },
          closeIcon: {
            className: 'text-white hover:text-dark-blue dark:text-white '
          }
        }}
        style={{ paddingLeft: 0 }}>
        <ul className="px-0 w-80 min-h-full text-lg text-neutral-10">
          <li className="flex items-center pb-10">
            <label tabIndex={0} className="outline-none">
              <LogoIcon
                className="w-8 h-8 fill-white dark:fill-light-blue"
                role="button"
                onClick={() => navigate('/')}
              />
            </label>
            <div className="font-semibold text-white dark:text-light-blue uppercase ml-2">Aero Design</div>
          </li>
          <li>
            <label>
              <Link
                to="/vehicles"
                className="hover:text-white outline-none"
                onClick={() => setShowSidebar(false)}>
                Cars
              </Link>
            </label>
          </li>
          <li className="mt-2">
            <label>
              <Link
                to="/submissions"
                className="hover:text-white outline-none"
                onClick={() => setShowSidebar(false)}>
                Submissions
              </Link>
            </label>
          </li>
          <li className="mt-2">
            <label>
              <Link
                to="/rescale-queue"
                className="hover:text-white outline-none"
                onClick={() => setShowSidebar(false)}>
                Task Queue
              </Link>
            </label>
          </li>
          <li className="mt-10 flex flex-col">
            <div className="text-gray-200">AERO GLOBAL SETTINGS</div>
            <hr className="my-2 gm-background h-[2px] border-0" />
            <label className="mt-1">
              <Link
                to="/methodologies"
                className="hover:text-white outline-none mt-4 cursor-pointer"
                onClick={() => setShowSidebar(false)}>
                Methodology Sets
              </Link>
            </label>
            <label htmlFor="main-drawer" className="mt-2">
              <Link
                to="/attitude-sets"
                className="hover:text-white outline-none cursor-pointer"
                onClick={() => setShowSidebar(false)}>
                Attitude Sets
              </Link>
            </label>
            <label htmlFor="main-drawer" className="mt-2">
              <Link
                to="/tyre-sets"
                className="hover:text-white outline-none cursor-pointer"
                onClick={() => setShowSidebar(false)}>
                Tyre Sets
              </Link>
            </label>
            <label htmlFor="main-drawer" className="mt-2">
              <Link
                to="/domain-sets"
                className="hover:text-white outline-none cursor-pointer"
                onClick={() => setShowSidebar(false)}>
                Domain Sets
              </Link>
            </label>
            <label htmlFor="main-drawer" className="mt-2">
              <Link
                to="/simulation-sets"
                className="hover:text-white outline-none cursor-pointer"
                onClick={() => setShowSidebar(false)}>
                Simulation Sets
              </Link>
            </label>
            <label htmlFor="main-drawer" className="mt-2 hidden">
              <Link
                to="/project-configs"
                className="hover:text-white outline-none cursor-pointer"
                onClick={() => setShowSidebar(false)}>
                Project Config
              </Link>
            </label>
            <label htmlFor="main-drawer" className="mt-2 hidden">
              <Link
                to="/workflows"
                className="hover:text-white outline-none cursor-pointer"
                onClick={() => setShowSidebar(false)}>
                Workflows
              </Link>
            </label>
          </li>
          <li className="mt-10 flex flex-col space-y-2">
            <div className="text-gray-200">SETTINGS</div>
            <hr className="my-2 gm-background h-[2px] border-0" />
            <div className='flex items-center'>
              <InputSwitch checked={theme === 'dark'} onChange={
                (e) => {
                  const newTheme = e.value ? 'dark' : 'light'
                  setTheme(newTheme)
                  localStorage.setItem('theme', newTheme)
                  if (newTheme === 'dark') {
                    document.body.classList.add('dark')
                  } else {
                    document.body.classList.remove('dark')
                  }
                }}
                pt={{
                  root: {
                    className: '!h-[16px] w-9'
                  },
                  slider: {
                    className: 'h-[16px] w-9 dark:!bg-neutral-10'
                  }
                }}
              />
              <label className='ml-2'>Dark Mode</label>
            </div>

            <label>
              <a
                href="#"
                className="hover:text-white outline-none mt-4 cursor-pointer"
                onClick={handleSignOut}>
                Sign Out
              </a>
            </label>
          </li>
        </ul>
      </Sidebar>
      <hr className="gm-background h-[2px] border-0" />
    </div>
  )
}
