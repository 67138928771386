import { ProgressSpinner } from 'primereact/progressspinner'
import { FC, useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router'
import { useRecoilState } from 'recoil'
import { useGetProject } from '../api/project-API'
import {
  useCommitRun,
  useRecommitRun,
  useGetRescaleJobStatus,
  useGetSubmission,
  useUpdateWorkflowSubmission,
  useAddRunConfigCase,
  useUpdateRunConfigCase,
  useDeleteRunConfigCase,
  useAddWorkflowRunGroup
} from '../api/submission-API'

import Modal from '../components/Modal'
import { protoService } from '../proto/ProtoService'
import { Project, RunConfigProto, RunStatus, WorkflowSubmission } from '../types/types'
import {
  currentProjectState,
  currentSubmissionState
} from '../utils/state-atoms'

import {
  RedoIcon,
  RefreshIcon,
  PlayIcon,
  PlusIcon
} from '../components/Icons'
import IconButton from '../components/buttons/IconButton'
import {
  useBreadcrumbNamesGet,
  useBreadcrumbRoutePathGet,
  useBreadcrumbTitlesGet,
  useBreadcrumbUrlsGet
} from '../contexts/breadcrumbContexts/BreadcrumbContext'
import Breadcrumbs from '../components/Breadcrumbs'
import { createBreadcrumbs } from '../utils/breadcrumb_paths'
import SubmissionModal from '../components/submission/SubmissionModal'
import RunDetailsPanel from '../components/submission/RunDetailsPanel'
import { RunConfigCase, WorkflowRunGroup } from '@aero-platform/shared'
import routes from '../routing/routes'
import useSetBreadcrumbs from '../hooks/useSetBreadcrumbs'
import ConfirmModal from '../components/ConfimModal'
import { cloneRunConfigCase } from '../types/util'
import CaseEditorModal from '../components/submission/CaseEditor'
import { confirmDialog } from 'primereact/confirmdialog'
import MasterGroupTable from '../components/submission/MasterGroupTable'
import CaseGroupTable from '../components/submission/CaseGroupTable'
import NewCaseGroupTable from '../components/submission/NewCaseGroupTable'

const Submission: FC = () => {
  const params = useParams()
  const refRefresh = useRef(true)
  const [currentProject, setCurrentProject] = useRecoilState(currentProjectState)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [rescaleStatusResponse, setRescaleStatusResponse] = useState<string>()
  const [showSubmissionConfirm, setShowSubmissionConfirm] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [currentWkflSubmission, setCurrentWkflSubmission] = useRecoilState(currentSubmissionState)
  const [{ data: submissionData, loading: loadingSubmission }, fetchSubmission] = useGetSubmission()
  const [{ data: projData }, fetchProject] = useGetProject()
  // const [{ data: statusData, loading: loadingStatus }, fetchJobStatus] = useGetRescaleJobStatus()
  const [{ loading: loadingStatus }, fetchJobStatus] = useGetRescaleJobStatus()
  const [{ loading: committingRun }, commitSubmission] = useCommitRun()
  const [{ loading: recommittingRun }, recommitSubmission] = useRecommitRun()
  const [{ loading: submittingCases }, submitCases] = useAddWorkflowRunGroup()

  const [selectedStates, setSelectedStates] = useState<string[]>([])
  const [submittedStates, setSubmittedStates] = useState<string[]>([])
  const [newCases, setNewCases] = useState<RunConfigCase[]>([])

  const [caseMode, setCaseMode] = useState<string | undefined>()
  const [selectedCaseName, setSelectedCaseName] = useState<string | undefined>()
  const [selectedCaseIndex, setSelectedCaseIndex] = useState<number>()
  const [showCaseEditor, setShowCaseEditor] = useState(false)

  const [{ loading: creatingCase }, createRunConfigCase] = useAddRunConfigCase()
  const [{ loading: deletingCase }, deleteRunConfigCase] = useDeleteRunConfigCase()
  const [{ loading: updatingCase }, updateRunConfigCase] = useUpdateRunConfigCase()

  /* Breadcrumb init and set */
  const { id } = useParams()

  const getBreadcrumbRoutePath = useBreadcrumbRoutePathGet()
  const ANCESTOR_API_ROUTES: string[] = getBreadcrumbRoutePath(routes.Submission.absolutePath)

  useSetBreadcrumbs(routes.Submission.absolutePath, id)

  // setting up breadcrumbs from Breadcrumb Tree for display
  const getBreadcrumbNames = useBreadcrumbNamesGet()
  const names: string[] = getBreadcrumbNames(ANCESTOR_API_ROUTES)
  const getBreadcrumbTitles = useBreadcrumbTitlesGet()
  const titles: string[] = getBreadcrumbTitles(ANCESTOR_API_ROUTES)
  const getBreadcrumbUrls = useBreadcrumbUrlsGet()
  const urls: string[] = getBreadcrumbUrls(ANCESTOR_API_ROUTES)

  const breadcrumbs = createBreadcrumbs(titles, names, urls)

  const [{ loading: updatingRun }, updateWorkflowSubmission] = useUpdateWorkflowSubmission(
    currentWkflSubmission?._id as string
  )

  const handleCommitNewCases = async () => {
    if (!currentWkflSubmission || submittingCases || newCases.length === 0) {
      return
    }

    try {
      await submitCases({
        runId: currentWkflSubmission._id,
        caseNames: newCases.map((item) => encodeURIComponent(item.name))
      } as any)
    } catch (err) {
      console.error(err)
      toast.error('Error submitting new cases.')
    }
  }


  const handleResubmit = async () => {
    if (!currentWkflSubmission) {
      return
    }
    if (!selectedStates.length) {
      toast.error('No states selected.')
      return
    }


    /*

    let needRefresh = false
    try {
      const existingWorkflows: string[] = []
      const existingGroups: WorkflowRunGroup[] = []
      const newStates: string[] = []
      for (let i = 0; i < selectedStates.length; i++) {
        const workflowRunGroup = currentWkflSubmission.workflowRunGroups.find((grp) => {
          return grp.name === selectedStates[i] || grp.name.endsWith('|' + selectedStates[i])
        })

        if (workflowRunGroup) {
          existingGroups.push(workflowRunGroup)
          workflowRunGroup.workflow && workflowRunGroup.workflow.forEach((item) => {
            existingWorkflows.push(item._id)
          })
        } else {
          newStates.push(selectedStates[i])
        }
      }

      if (existingGroups.length) {
        const failedGroup: string[] = []
        for (let i = 0; i < existingGroups.length; i++) {
          try {
            await recommitSubmission({
              runId: currentWkflSubmission._id,
              workflowRunGroupSysId: existingGroups[i].systemRunId
            } as any)
            needRefresh = true
            toast.success(existingGroups[i].name + ' resubmitted.')
          } catch (err) {
            failedGroup.push(existingGroups[i].name)
          }
        }
        if (failedGroup.length) {
          toast.error(`Failed to resubmit existing states: ${failedGroup.join(', ')}`)
        }
      }
    } catch (err) {
      console.error(err)
      toast.error('Error submitting workflow.')
    }

    needRefresh && fetchSubmission().catch((err) => {
      console.error(err)
      toast.error('Error reloading workflow submission.')
    })
      */
  }

  const organizeNewCases = (submission: WorkflowSubmission) => {
    const tmpNewCases: RunConfigCase[] = []
    const processedCaseNames: string[] = []
    if (submission.submissionRunGroups) {
      submission.submissionRunGroups.forEach((subGroupItem) => {
        subGroupItem.workflowRunGroups.forEach((grp) => {
          processedCaseNames.push(grp.name)
        })
      })
    }

    submission.runConfig.selectedCases.forEach((caseItem) => {
      if (!processedCaseNames.includes(caseItem.name)) {
        tmpNewCases.push(caseItem)
      }
    })

    tmpNewCases.reverse()
    setNewCases(tmpNewCases)
  }

  const reloadSubmission = () => {
    if (!refRefresh.current) {
      return
    }

    fetchSubmission(params.id).catch((err) => {
      console.error(err)
      toast.error('Error loading workflow submission.')
    })
  }

  useEffect(() => {
    fetchSubmission(params.id).catch((err) => {
      console.error(err)
      toast.error('Error loading workflow submission.')
    })

    const intervalId = setInterval(() => {
      reloadSubmission()
    }, 5000); // Update every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [])

  useEffect(() => {
    if (submissionData) {
      const submission = protoService.decodeProtoResponse(
        submissionData,
        'workflowsubmission'
      ) as WorkflowSubmission[]
      if (submission.length) {
        // setWorkflowsByState(organizeWorkflows(submission[0]))
        // autoSelectStates(submission[0])
        organizeNewCases(submission[0])
        submission[0].submissionRunGroups && submission[0].submissionRunGroups.reverse()
        setCurrentWkflSubmission(submission[0])
        if (currentProject) {
          if (currentProject._id !== submission[0].projectId) {
            fetchProject(submission[0].projectId)
          }
        } else {
          fetchProject(submission[0].projectId)
        }
      } else {
        toast.error('The run does not exist.')
      }
    }
  }, [submissionData])

  /*
  function organizeWorkflows(submission: WorkflowSubmission) {
    const tempMap = new Map()
    submission.runConfig.selectedCases.forEach((caseItem) => {
      const runGroup = submission.workflowRunGroups.find((grp) => {
        return caseItem?.name === grp.name
      })

      tempMap.set(caseItem.name.toString(), runGroup)
    })

    // filter out master workflows
    const masterGrp = submission.workflowRunGroups.filter((grp) => {
      // @ts-ignore
      return !grp.dependent
    })
    setMasterWorkflows(masterGrp)

    return tempMap
  }
    */

  /*
  function autoSelectStates(submission: WorkflowSubmission) {
    const newStates: string[] = []
    const oldStates: string[] = []

    submission.runConfig.selectedCases.forEach((caseItem) => {
      const runGroup = submission.workflowRunGroups.find((grp) => {
        return caseItem?.name === grp.name || grp.name.endsWith('|' + caseItem?.name)
      })
      if (runGroup) {
        oldStates.push(caseItem.name)
      } else {
        newStates.push(caseItem.name)
      }
    })
    setSubmittedStates(oldStates)
    setSelectedStates(newStates)
  }
    */

  useEffect(() => {
    if (projData && projData.protoBytesList.length) {
      const proj = protoService.deSerialize(projData.protoBytesList[0], 'project')
      setCurrentProject(proj)
    }
  }, [projData])

  useEffect(() => {
    if (loadingSubmission || showCaseEditor || showEditModal || showStatusModal || showSubmissionConfirm) {
      refRefresh.current = false
    } else {
      refRefresh.current = true
    }
  }, [loadingSubmission, showCaseEditor, showEditModal, showStatusModal, showSubmissionConfirm])

  const handleDeleteCase = (submissionGroupName: string, caseName: string, caseIndex: number) => {
    confirmDialog({
      message: `Are you sure you want to delete the run case '${caseName}' ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      rejectClassName: 'bg-gray-500 text-white',
      accept: () => {
        deleteRunConfigCase({
          id: currentWkflSubmission?._id,
          submissionGroupName,
          caseName,
        } as any
        ).then(() => {
          toast.success('Case deleted.')
        }).catch((err) => {
          console.error(err)
          toast.error('Error deleting case.')
        })
      },
      reject: () => {
      }
    })
  }

  const handleSubmit = async () => {
    if (!currentWkflSubmission) {
      return
    }
    setShowSubmissionConfirm(true)
  }

  const submitRun = async () => {
    try {
      await commitSubmission(currentWkflSubmission?._id)
      toast.success('Workflow submitted.')

      fetchSubmission(params.id).catch((err) => {
        console.error(err)
        toast.error('Error loading workflow submission.')
      })
    } catch (err) {
      console.error(err)
      toast.error('Error submitting workflow.')
    }
  }

  const handleRefresh = async () => {
    if (!currentWkflSubmission || loadingSubmission) {
      return
    }

    try {
      fetchSubmission(params.id).catch((err) => {
        console.error(err)
        toast.error('Error refreshing run status.')
      })
    } catch (err) {
      console.error(err)
      toast.error('Error refreshing run status.')
    }
  }

  if (!currentWkflSubmission && loadingSubmission) {
    return (
      <div className="relative w-full flex items-center justify-center text-center mt-10 h-full">
        <ProgressSpinner
          aria-label="Loading"
          pt={{
            circle: { className: '!stroke-dark-blue' }
          }}
        />
      </div>
    )
  }

  const updateRun = async (runConfig: RunConfigProto, submission: WorkflowSubmission) => {
    if (!currentWkflSubmission) {
      return
    }
    console.log('---->', runConfig, submission)
    //create new runConfig
    let newRunConfig

    //save submission
    submission.overallStatus = RunStatus.CREATED
    let newWorkflowSubmission: WorkflowSubmission
    try {
      // prepare workflow submission
      submission.runConfig = runConfig
      const dataToPost = protoService.encodePayload(submission, 'workflowsubmission')
      const submissionRes = await updateWorkflowSubmission({
        url: `/WorkflowSubmissions/${currentWkflSubmission._id}`,
        data: dataToPost
      })
      newWorkflowSubmission = protoService.deSerialize(
        submissionRes.data.protoBytesList[0],
        'workflowsubmission'
      )
      toast.success('Workflow submission saved.')
      setShowEditModal(false)
      fetchSubmission(params.id)
    } catch (err) {
      console.error(err)
      toast.error('Error saving workflow submission.')
      return
    }
  }

  //console.log("currentWkflSubmission", currentWkflSubmission)
  //currentWkflSubmission && console.log("currentWkflSubmission.runConfig.selectedCases", currentWkflSubmission.runConfig.selectedCases)

  return (
    <>
      <div className="relative w-full flex flex-col items-stretch mt-4 pb-10 space-y-4">
        {!currentWkflSubmission && !loadingSubmission && (
          <div className="relative w-full flex items-center justify-center text-center mt-10 h-full">
            {`The run ${params.id} does not exist.`}
          </div>
        )}
        {currentWkflSubmission && (
          <>
            <section className="flex items-center justify-between space-x-2">
              <Breadcrumbs ancestorBreadcrumbs={breadcrumbs} />
              <div className='flex items-center justify-end space-x-2'>
                {currentWkflSubmission && currentWkflSubmission.overallStatus === RunStatus.CREATED && false && (
                  <IconButton
                    onClick={handleSubmit}
                    severity='secondary'
                    disabled={newCases.length === 0}
                    loading={committingRun || recommittingRun}
                    title="Submit"
                    icon={<PlayIcon className="fill-white h-4 w-4" />}
                    dataPrAt={'center bottom'}
                  />
                )}
                <IconButton
                  onClick={handleRefresh}
                  disabled={loadingSubmission || committingRun}
                  title="Refresh"
                  severity='info'
                  icon={<RefreshIcon className="fill-white h-3 w-3 m-[2px]" />}
                  loading={loadingSubmission}
                />
              </div>
            </section>

            <RunDetailsPanel submission={currentWkflSubmission} setShowEditModal={setShowEditModal} />

            <section className="flex items-center justify-between">
              <div className="flex text-gm-primary-90 text-xl pt-4 dark:text-light-blue">
                Run Cases
              </div>
              <div className='flex items-center justify-end space-x-2'>
                {currentWkflSubmission && currentWkflSubmission.overallStatus !== RunStatus.COMPLETED && (
                  <span className="ml-2">
                    <IconButton
                      title="Add New Case"
                      severity="secondary"
                      onClick={() => {
                        setCaseMode('new')
                        setSelectedCaseName('')
                        setShowCaseEditor(true)
                      }}
                      loading={creatingCase}
                      icon={<PlusIcon className="fill-white w-4 h-4" />}
                    />
                  </span>
                )}
                {currentWkflSubmission && newCases.length > 0 && (
                  <span className="ml-2">
                    <IconButton
                      title="Submit Cases"
                      severity="secondary"
                      onClick={handleCommitNewCases}
                      loading={submittingCases}
                      icon={<RedoIcon className="fill-white w-4 h-4" />}
                    />
                  </span>
                )}
                {false &&
                  <IconButton
                    title="Resubmit"
                    severity="danger"
                    onClick={handleResubmit}
                    loading={recommittingRun}
                    icon={<RedoIcon className="fill-white w-4 h-4" />}
                  />}
              </div>
            </section>

            {newCases.length > 0 && (
              <section className="flex flex-col items-stretch">
                <div className="flex text-gm-primary-90 pt-4 dark:text-light-blue text-lg font-semibold">
                  New Cases
                </div>
                <div className="pl-4">
                  <NewCaseGroupTable
                    cases={newCases}
                    submissionGroupName="new"
                    workflowSubmissionId={currentWkflSubmission._id}
                    isCommitting={committingRun || recommittingRun}
                    isDeletingCase={deletingCase}
                    isUpdatingCase={updatingCase}
                    onEditCase={(submissionGroupName: string, caseName: string, caseIndex: number) => {
                      setCaseMode('edit')
                      setSelectedCaseName(caseName)
                      setSelectedCaseIndex(caseIndex)
                      setShowCaseEditor(true)
                    }}
                    onDeleteCase={handleDeleteCase}
                  /></div>
              </section>
            )}

            {currentWkflSubmission && currentWkflSubmission.submissionRunGroups &&
              currentWkflSubmission.submissionRunGroups.map((srgItem) => {
                return (
                  <section className='flex flex-col items-stretch' key={srgItem.name}>
                    <div className="text-lg font-semibold">{srgItem.name}</div>
                    <div className="ml-4 flex text-gm-primary-90 text-lg pt-4 dark:text-light-blue">Master Run Group</div>
                    <div className="ml-4">
                      <MasterGroupTable
                        workflowSubmissionId={currentWkflSubmission._id}
                        masterWorkflowRunGroup={srgItem.workflowRunGroups.filter((item) => item.name === 'All')}
                      />
                    </div>

                    <div className="ml-4 flex text-gm-primary-90 text-lg pt-4 dark:text-light-blue">Cases</div>

                    <div className="pl-8">
                      <CaseGroupTable
                        submissionGroupName={srgItem.name}
                        runId={currentWkflSubmission._id}
                        referenceRunId={currentWkflSubmission.runConfig.referenceRunName}
                        workflowRunGroups={srgItem.workflowRunGroups.filter((item) => item.name !== 'All')}
                        isCommitting={committingRun || recommittingRun}
                        isDeletingCase={deletingCase}
                        isUpdatingCase={updatingCase}
                        onEditCase={(submissionGroupName: string, caseName: string, caseIndex: number) => {
                          setCaseMode('edit')
                          setSelectedCaseName(caseName)
                          setSelectedCaseIndex(caseIndex)
                          setShowCaseEditor(true)
                        }}
                        onDeleteCase={handleDeleteCase}
                      />
                    </div>

                  </section>
                )
              }
              )
            }
          </>
        )}
      </div >
      {showStatusModal && (
        <Modal
          title="Rescale Job Status"
          isOpen={showStatusModal}
          withAction={false}
          easyClose={true}
          onClose={() => setShowStatusModal(false)}>
          {loadingStatus && (
            <div className="w-full flex items-center min-h-[300px]">
              <ProgressSpinner
                aria-label="Loading"
                pt={{
                  circle: { className: '!stroke-dark-blue' }
                }}
              />
            </div>
          )}
          {!loadingStatus && rescaleStatusResponse && (
            <pre
              className="whitespace-pre-wrap overflow-y-auto max-h-[70vh]"
              style={{ maxHeight: 'calc(100%-100px) !important' }}>
              {rescaleStatusResponse}
            </pre>
          )}
        </Modal>
      )
      }
      {
        showEditModal && (
          <SubmissionModal
            mode="edit"
            submitting={false}
            project={currentProject as Project}
            existingRun={currentWkflSubmission as WorkflowSubmission}
            onCancel={() => setShowEditModal(false)}
            onConfirm={(runconfig, submission) => {
              updateRun(runconfig, submission)
            }}
          />
        )
      }
      {
        showSubmissionConfirm && (
          <ConfirmModal
            message='Are you sure you want to submit the run?'
            onCancel={() => setShowSubmissionConfirm(false)}
            onConfirm={() => {
              setShowSubmissionConfirm(false)
              submitRun()
            }}
          />
        )
      }
      {
        showCaseEditor && (
          <CaseEditorModal
            mode={caseMode}
            caseName={selectedCaseName}
            caseIndex={selectedCaseIndex}
            submitting={creatingCase || updatingCase}
            submission={currentWkflSubmission as WorkflowSubmission}
            onCancel={() => {
              setShowCaseEditor(false)
            }}
            onConfirm={async (runConfigCase: RunConfigCase, mode: string) => {
              try {
                if (currentWkflSubmission) {
                  if (mode === 'edit') {
                    const dataToPost = protoService.encodePayload(cloneRunConfigCase(runConfigCase), 'runconfigcase')
                    // @ts-ignore
                    updateRunConfigCase({ id: currentWkflSubmission._id, data: dataToPost } as any)
                    // run case will reload automatically
                    toast.success('Case updated.')
                  } else {
                    const dataToPost = protoService.encodePayload(cloneRunConfigCase(runConfigCase), 'runconfigcase')
                    // @ts-ignore
                    createRunConfigCase({ id: currentWkflSubmission._id, data: dataToPost } as any)
                    // run case will reload automatically
                    toast.success('New case created.')
                  }
                  setShowCaseEditor(false)
                }
              } catch (err) {
                console.error(err)
                toast.error('Error saving run config case.')
              }
            }}
          />
        )
      }


    </>
  )
}


export default Submission
