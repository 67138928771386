import { Column } from 'primereact/column'
import { DataTable, DataTablePageEvent, SortOrder } from 'primereact/datatable'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RunStatus, WorkflowSubmission } from '../../types/types'
import { CompareIcon, PlayIcon } from '../Icons'
import IconButton from '../buttons/IconButton'
import SubmissionStatusCell from './SubmissionStatusCell'
import { InputText } from 'primereact/inputtext'
import { useCommitRun, useGetSubmission } from '../../api/submission-API'
import toast from 'react-hot-toast'
import { protoService } from '../../proto/ProtoService'

const filterComp = (options) => {
  return (
    <div className="px-1 py-2">
      <InputText value={options.value} onChange={(e) => {
        console.log('FilterElement', options)
        options.filterApplyCallback(e.target.value)
      }} className="w-full" />
    </div>
  )
}

const columns = [
  {
    header: 'ID',
    field: 'shortName',
    sortable: true,
    headerClassName: 'w-[120px]',
    bodyClassName: 'w-[120px] id-cell',
    filter: true,
    filterElement: filterComp,
    filterFunction: (value, filter) => {
      console.log('filterFunction', value, filter)
      return value && value.toString().includes(filter)
    },
    body: (rowData) => {
      const shortName = rowData.shortName
      return <Link to={`/submissions/${rowData.name}`}>{shortName}</Link>
    }
  },
  {
    header: 'Description',
    field: 'runConfig.description',
  },
  {
    header: 'Reference Run',
    field: 'runConfig.referenceRunName',
    filter: true,
    sortable: true,
    headerClassName: 'w-[150px]',
  },
  {
    header: 'Master Geometry',
    field: 'runConfig.baselineRunName',
    filter: true,
    sortable: true,
    headerClassName: 'w-[150px]',
  },
  {
    header: 'Overall Status',
    field: 'overallstatus',
    sortable: true,
    headerClassName: 'w-[200px]',
    body: (rowData: WorkflowSubmission) => {
      return <SubmissionStatusCell status={rowData.overallStatus} />
    }
  }
]

type SubmissionListProp = {
  loading?: boolean,
  onPageChange: (event: DataTablePageEvent) => void
  submissions: WorkflowSubmission[]
}

const SubmissionList: FC<SubmissionListProp> = ({ submissions, loading, onPageChange }: SubmissionListProp) => {
  const [runList, setRunList] = useState([] as WorkflowSubmission[])
  const [sortField, setSortField] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<SortOrder>(-1)

  const [activeRunId, setActiveRunId] = useState<string>('')
  const [idForPeriview, setIdForPeriview] = useState<string>()
  const [{ loading: committingSubmission }, commitSubmission] = useCommitRun()
  const [{ loading: loadingRun }, loadRun] = useGetSubmission()

  useEffect(() => {
    setRunList([...submissions])
  }, [submissions])

  const handleSubmitRun = async (runId: string) => {
    if (committingSubmission) {
      return
    }
    try {
      // commit submission, start run
      setActiveRunId(runId)
      await commitSubmission(runId)
      toast.success('Workflow submitted.')
      setActiveRunId('')
      setRunList(
        runList.map((item) => {
          if (item._id === runId) {
            return {
              ...item,
              overallStatus: RunStatus.SUBMITTED
            } as WorkflowSubmission
          }
          return item
        })
      )
    } catch (err) {
      console.error(err)
      toast.error('Error submitting workflow.')
    }
  }

  return (
    <>
      <DataTable
        value={runList}
        dataKey="name"
        filterDisplay='row'
        loading={loading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={(evt) => {
          setSortField(evt.sortField)
          setSortOrder(evt.sortOrder)
        }}
        pt={{
          root: {
            className: 'mt-4'
          },
          bodyRow: {
            className: 'table-row'
          },
        }}>
        {columns.map((item) => {
          return (
            <Column
              key={item.field}
              field={item.field}
              header={item.header}
              body={item.body}
              sortable={item.sortable}
              filterElement={item.filterElement}
              filter={item.filter}
              filterMatchMode='contains'
              filterFunction={item.filterFunction}
              filterField={item.field}
              showFilterMenu={false}
              headerClassName={item.headerClassName}
              bodyClassName={item.bodyClassName}
            ></Column>
          )
        })}
        <Column
          rowEditor
          body={(rowData, options) => {
            return (
              <div className="flex items-center space-x-2">
                {rowData.overallStatus === RunStatus.CREATED &&
                  <IconButton
                    title='Submit'
                    severity="secondary"
                    loading={activeRunId === rowData._id && committingSubmission}
                    onClick={() => handleSubmitRun(rowData._id)}
                    icon={<PlayIcon className="fill-white" />}
                  />}
                <IconButton
                  title='Compare'
                  severity="info"
                  onClick={() => { }}
                  icon={<CompareIcon className="fill-white" />}
                />
              </div>
            )
          }}
          headerClassName="editor-header"
          headerStyle={{ width: '100px' }}
          pt={{
            root: {
              className: 'px-2 py-2 text-center'
            }
          }}></Column>
      </DataTable>
    </>
  )
}

export default SubmissionList
