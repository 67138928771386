import axios, { AxiosError } from 'axios'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useCreateVehicle, useUpdateVehicle } from '../../api/vehicle-API'
import { protoService } from '../../proto/ProtoService'
import { Vehicle } from '../../types/types'
import { currentTeamState, vehicleListState } from '../../utils/state-atoms'
import Modal from '../Modal'
import CloseButton from '../buttons/CloseButton'

type VehicleModalProps = {
  mode: string
  initVehicle: Vehicle
  onConfirm: (vehicle: Vehicle) => void
  onCancel: () => void
}

const editorTitle = {
  new: 'Create New Car',
  edit: 'View/Edit Car'
}

const VehicleModal: FC<VehicleModalProps> = ({ mode, initVehicle, onConfirm, onCancel }) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Vehicle>()
  const currentTeam = useRecoilValue(currentTeamState)
  const [vehiclemetries, setVehicles] = useRecoilState<Vehicle[]>(vehicleListState)

  const [{ data: createResData, loading: creating }, createVehicle] = useCreateVehicle()
  const [{ data: updateResData, loading: updating }, updateVehicle] = useUpdateVehicle(
    initVehicle._id
  )

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name].message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const onSubmit: SubmitHandler<Vehicle> = async (formData: Vehicle) => {
    try {
      if (mode === 'new') {
        const newVehicleData = {
          ...formData,
          name: 'C' + formData.vehicleId,
          raceTeamId: currentTeam
        }
        console.log('newVehicleData=', newVehicleData)
        const dataToPost = protoService.encodePayload(newVehicleData, 'vehicle')
        const res = await createVehicle({ data: dataToPost })
        console.log(res)
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const newVehicle: Vehicle = protoService.deSerialize(
            res.data.protoBytesList[0],
            'vehicle'
          )
          onConfirm && onConfirm(newVehicle)
          setVehicles([newVehicle, ...vehiclemetries])
        }
      } else if (mode === 'edit') {
        const newVehicleData = new Vehicle(initVehicle)
        newVehicleData.vehicleId = formData.vehicleId
        newVehicleData.name = 'C' + formData.vehicleId
        newVehicleData.description = formData.description
        console.log('newVehicleData=', newVehicleData)
        const dataToPost = protoService.encodePayload(newVehicleData, 'vehicle')
        const res = await updateVehicle({ data: dataToPost })
        console.log(res)
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const newVehicle: Vehicle = protoService.deSerialize(
            res.data.protoBytesList[0],
            'vehicle'
          )
          console.log('Updated Vehicle=', newVehicle)
          onConfirm && onConfirm(newVehicleData)
          setVehicles(
            vehiclemetries.map((item) => {
              return item._id === newVehicleData._id ? newVehicleData : item
            })
          )
        }
      }
      toast.success('Vehicle saved.')
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError
        if (error.response?.status === 302) {
          toast.error(error.response?.data as string)
        } else {
          toast.error('Error saving vehicle.')
        }
      }
    }
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  return (
    <Modal
      size="md"
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={editorTitle[mode]}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className="relative">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-1 gap-x-10 gap-y-4 mt-8">
          <div className="flex flex-col items-stretch">
            <Controller
              name="vehicleId"
              control={control}
              rules={{ required: 'Vehicle ID is required.' }}
              defaultValue={initVehicle.vehicleId}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Car ID
                  </label>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={classNames({ error: fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="flex flex-col items-stretch">
            <Controller
              name="description"
              control={control}
              defaultValue={initVehicle.description}
              rules={{ required: 'Description is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Car Description
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <Button type="submit" label="Save" loading={creating || updating} />
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default VehicleModal
