import { KeyboardEvent as KBEvent, MouseEvent, PropsWithChildren, useEffect } from 'react'
import ReactPortal from './ReactPortal'
import { CloseIcon } from './Icons'
import IconButton from './buttons/IconButton'
import { classNames } from 'primereact/utils'

type ModalProps = {
  onCancel?: () => void
  onConfirm?: () => void
  onClose?: () => void
  isOpen: boolean
  easyClose?: boolean
  showClose?: boolean
  withAction: boolean
  title: string
  showCancelButton?: boolean
  cancelButtonDisabled?: boolean
  confirmButtonDisabled?: boolean
  cancelButtonCaption?: string
  confirmButtonCaption?: string
  zIndex?: number
  size?: 'sm' | 'md' | 'lg'
}

const Modal = ({
  title,
  isOpen,
  zIndex = 20,
  size = 'lg',
  withAction = true,
  showCancelButton = true,
  cancelButtonDisabled = false,
  confirmButtonDisabled = false,
  cancelButtonCaption = 'Cancel',
  confirmButtonCaption = 'OK',
  easyClose = false,
  showClose = true,
  onConfirm,
  onCancel,
  onClose,
  children
}: ModalProps & PropsWithChildren) => {
  useEffect(() => {
    const handelKeydown = (evt: KeyboardEvent) => {
      if (evt.code === 'Escape') {
        onClose && onClose()
      }
    }
    document.addEventListener('keydown', handelKeydown)
    return () => {
      document.removeEventListener('keydown', handelKeydown)
    }
  }, [])

  if (!isOpen) {
    return null
  }

  const handleClickOutside = (evt: MouseEvent<HTMLDivElement>) => {
    const div = evt.target as HTMLDivElement
    div.id === 'outside-container' && easyClose && onClose && onClose()
  }

  const handleKeyDown = (evt: KBEvent<HTMLDivElement>) => {
    easyClose && evt.code === 'Escape' && onClose && onClose()
  }

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <div className="fixed opacity-90 bg-dark-blue dark:bg-neutral-80 inset-0 ease-in" style={{ zIndex: zIndex }}></div>
      <div
        id="outside-container"
        className="flex justify-center items-center rounded-md fixed shadow-xl inset-0 dark:text-neutral"
        onClick={handleClickOutside}
        style={{ zIndex: 1 + zIndex }}
        onKeyDown={handleKeyDown}>
        <div className={
          classNames(
            "bg-white dark:bg-neutral-90 p-6 flex flex-col items-stretch md:max-h-[80%] max-w-7xl mx-auto rounded-lg",
            { 'w-[80%]': size === 'lg', 'w-[50%]': size === 'md', 'w-[300px]': size === 'sm' }
          )}>
          <div className='flex items-center justify-between basis-10 '>
            <h2 className="flex items-center text-xl text-dark-blue dark:text-neutral-10 ">
              {title}
            </h2>
            {showClose &&
              <IconButton
                title='Close'
                link
                icon={<CloseIcon className='w-6 h-6 fill-black dark:fill-light-blue' />}
                onClick={() => { onCancel && onCancel() }}
              />}
          </div>
          <div className="flex flex-col items-stretch basis-0 grow">{children}</div>
          {withAction && (
            <div className="flex items-center basis-10 space-x-4 pt-4">
              <button
                disabled={confirmButtonDisabled}
                onClick={() => {
                  onConfirm && onConfirm()
                }}
                className="btn bg-blue-900 text-white">
                {confirmButtonCaption}
              </button>
              {showCancelButton && (
                <button
                  disabled={cancelButtonDisabled}
                  onClick={() => {
                    onCancel && onCancel()
                  }}
                  className="btn bg-blue-900 text-white">
                  {cancelButtonCaption}
                </button>
              )}
            </div>
          )}
        </div>
      </div >
    </ReactPortal >
  )
}

export default Modal
