import { UseAxiosResult } from "axios-hooks"
import { AxiosResponseProtoData } from "../types/types"
import { useAxiosClient } from "./configs/axios-setup"

export interface IRequestError {
  status: number
  text: string
}

export const useGetRescaleQueue = (): UseAxiosResult<any> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_WORKFLOW_SUBMISSION_API_BASE_URL,
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = () => {
    return doFetch({
      url: `/JobQueue/2/jobQueue/?`,
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useReleaseFromQueue = (): UseAxiosResult<any> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_WORKFLOW_SUBMISSION_API_BASE_URL,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({gmRefString, releaseFromQueueOnly}: any) => {
    return doFetch({
      url: `/JobQueue/2/releaseFromQueue/?gmRefString=${gmRefString}&releaseFromQueueOnly=${releaseFromQueueOnly}`,
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useSetQueuePriority = (): UseAxiosResult<any> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_WORKFLOW_SUBMISSION_API_BASE_URL,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({gmRefString, priority}: any) => {
    return doFetch({
      url: `/JobQueue/2/setQueuePriority/?gmRefString=${gmRefString}&priority=${priority}`,
    })
  }
  
  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}