import { Route, Routes } from 'react-router-dom'
import AuthLayout from '../components/AuthLayout'
import MainLayout from '../components/MainLayout'
import DataOverview from '../pages/DataOverview'
import EditProject from '../pages/EditProject'
import ErrorPage from '../pages/Error'
import Geometries from '../pages/Geometries'
import Login from '../pages/Login'
import NewProject from '../pages/NewProject'
import Profile from '../pages/Profile'
import ProjectConfigs from '../pages/ProjectConfigs'
import Projects from '../pages/Projects'
import Register from '../pages/Register'
import Submission from '../pages/Submission'
import Submissions from '../pages/Submissions'
import TyreSets from '../pages/TyreSets'
import DomainSets from '../pages/DomainSets'
import Vehicles from '../pages/Vehicles'
import AttitudeSets from '../pages/AttitudeSets'
import NotFound from './NotFound'
import RequireAuth from './RequireAuth'
import routes from './routes'
import Workflows from '../pages/Workflows'
import MethodologySets from '../pages/MethodologySets'
import SimulationSets from '../pages/SimulationSet'
import RescaleQueue from '../pages/RescaleQueue'

const Routing = () => {
  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />} errorElement={<ErrorPage />}>
        <Route path={routes.Login.relativePath} element={<Login />} />
        <Route path={routes.Register.relativePath} element={<Register />} />
      </Route>
      <Route path={routes.Home.relativePath} element={<MainLayout />} errorElement={<ErrorPage />}>
        <Route index element={<RequireAuth element={<Vehicles />} />} />
        <Route
          path={routes.Vehicles.relativePath}
          element={<RequireAuth element={<Vehicles />} />}
        />
        <Route
          path={routes.Projects.relativePath}
          element={<RequireAuth element={<Projects />} />}
        />
        <Route
          path={routes.DataOverview.relativePath}
          element={<RequireAuth element={<DataOverview />} />}
        />
        <Route path={routes.Profile.relativePath} element={<RequireAuth element={<Profile />} />} />
        <Route
          path={routes.MethodologySets.relativePath}
          element={<RequireAuth element={<MethodologySets />} />}
        />
        <Route
          path={routes.ProjectConfigs.relativePath}
          element={<RequireAuth element={<ProjectConfigs />} />}
        />
        <Route
          path={routes.Workflows.relativePath}
          element={<RequireAuth element={<Workflows />} />}
        />
        <Route
          path={routes.TyreSets.relativePath}
          element={<RequireAuth element={<TyreSets />} />}
        />
        <Route
          path={routes.DomainSets.relativePath}
          element={<RequireAuth element={<DomainSets />} />}
        />
        <Route
          path={routes.SimulationSets.relativePath}
          element={<RequireAuth element={<SimulationSets />} />}
        />
        <Route
          path={routes.AttitudeSets.relativePath}
          element={<RequireAuth element={<AttitudeSets />} />}
        />
        <Route
          path={routes.Geometries.relativePath}
          element={<RequireAuth element={<Geometries />} />}
        />
        <Route
          path={routes.NewProject.relativePath}
          element={<RequireAuth element={<NewProject />} />}
        />
        <Route
          path={routes.EditProject.relativePath}
          element={<RequireAuth element={<EditProject />} />}
        />
        <Route
          path={routes.Submission.relativePath}
          element={<RequireAuth element={<Submission />} />}
        />
        <Route
          path={routes.Submissions.relativePath}
          element={<RequireAuth element={<Submissions />} />}
        />
        <Route 
          path={routes.RescaleQueue.relativePath}
          element={<RequireAuth element={<RescaleQueue />} />}
        />
        </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Routing
